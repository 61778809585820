import { FC, Fragment, useContext } from "react";
import classNames from "classnames/dedupe";
import { cn } from "helpers/bem";
import "./styles.scss";
import Logo from "assets/images/logo.svg";
import { IconButton } from "components/IconButton/IconButton";
import { UserIcon } from "@heroicons/react/24/solid";
import { Menu, Transition } from "@headlessui/react";
import { AuthContext } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes";

const b = cn("app-header");

interface Props {
  className?: string;
}

export const AppHeader: FC<Props> = (props) => {
  const { className } = props;
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <div className={classNames(b(), className)}>
      <header className="bg-white">
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between h-16 px-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-6 w-auto" src={Logo} alt="" />
            </a>
          </div>

          <Menu as="div" className="relative inline-block text-left mr-2">
            <div className="">
              <Menu.Button>
                <IconButton icon={<UserIcon />} />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-20">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={() => {
                          logout();
                          navigate(ROUTES.AUTH);
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        Выйти
                      </a>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </nav>
      </header>
    </div>
  );
};
