import { FC } from "react";
import classNames from "classnames/dedupe";
import { cn } from "helpers/bem";
import "./styles.scss";
import Logo from "assets/images/logo.svg";
import { AuthForm } from "./components/AuthForm/AuthForm";

const b = cn("auth-page");

export const AuthPage: FC = () => {
  return (
    <div className={classNames(b())}>
      <img
        className={classNames(b("logo"), "h-[48px]")}
        src={Logo}
        alt="logo"
      />
      <AuthForm />
    </div>
  );
};
