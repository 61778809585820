import { FC } from "react";
import classNames from "classnames/dedupe";
import { cn } from "helpers/bem";
import "./styles.scss";

const b = cn("desc-info");

interface Props {
  className?: string;
  desc: string;
  text: string;
}

export const DescInfo: FC<Props> = (props) => {
  const { className, desc, text } = props;

  return (
    <div className={classNames(b(), className)}>
      <span className="block text-sm font-medium leading-6 text-gray-900">
        {desc}
      </span>
      <span className="text-sm text-black font-semibold">{text}</span>
    </div>
  );
};
