import { FC } from "react";
import classNames from "classnames/dedupe";
import { cn } from "helpers/bem";
import "./styles.scss";
import { AppButton, AppModal } from "components";
import { ButtonColors } from "types/ButtonColors";
import { useMutation } from "@apollo/client";
import { DELETE_USER } from "services/apollo/users";

const b = cn("delete-user-modal");

interface Props {
  className?: string;
  open: boolean;
  setOpen: any;
  updateUsers: () => void;
  selected: string[];
  currentID: null | string;
}

export const DeleteUserModal: FC<Props> = (props) => {
  const {
    className,
    open,
    setOpen,
    updateUsers,
    selected,
    currentID = null,
  } = props;

  const [deleteUser] = useMutation(DELETE_USER);

  async function handleDeleteUsers(userIDs: string[]) {
    await deleteUser({
      variables: {
        userIDs,
      },
    });
    await updateUsers();

    setOpen(false);
  }

  return (
    <AppModal
      className={classNames(b(), className)}
      open={open}
      setOpen={setOpen}
    >
      <h3 className="font-medium mb-4">Подтверждение удаления</h3>

      <p className="text-sm text-gray-500 mb-6">
        Вы уверены что хотите удалить пользователей?
      </p>

      <div className="flex justify-end gap-2">
        <AppButton
          text="Удалить"
          color={ButtonColors.RED}
          onClick={() =>
            handleDeleteUsers(currentID ? [currentID] : [...selected])
          }
        />
        <AppButton
          text="Отмена"
          color={ButtonColors.GRAY}
          onClick={() => setOpen(false)}
        />
      </div>
    </AppModal>
  );
};
