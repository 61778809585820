import React, { FC, FormEvent } from "react";
import classNames from "classnames/dedupe";
import { cn } from "helpers/bem";
import "./styles.scss";
import { AppInput } from "components";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

const b = cn("app-search");

interface Props {
  className?: string;
  value: string;
  onChange: (e: FormEvent<HTMLInputElement>) => void;
}

export const AppSearch: FC<Props> = (props) => {
  const { className, value, onChange } = props;

  return (
    <div className={classNames(b(), className)}>
      <MagnifyingGlassIcon className={b("icon")} />
      <AppInput
        value={value}
        id="search"
        onChange={onChange}
        placeholder="Поиск..."
      />
    </div>
  );
};
