export const roleOptions = [
  {
    key: "MANAGER",
    name: "Менеджер",
  },
  {
    key: "ADMIN",
    name: "Администратор",
  },
  {
    key: "SUPERADMIN",
    name: "Суперадмин",
  },
];

export const tariffOptions = [
  {
    key: "FREE",
    name: "FREE",
  },
  {
    key: "PLUS",
    name: "PLUS",
  },
  {
    key: "ENTERPRISE",
    name: "ENTERPRISE",
  },
];

export const statusOptions = [
  {
    key: "ACTIVE",
    name: "Активный",
  },
  {
    key: "INACTIVE",
    name: "Отключен",
  },
  {
    key: "PAY_WAIT",
    name: "Ожидает оплаты",
  },
];

export const STORAGE_KEYS = {
  ACCESS: "accessToken",
  REFRESH: "refreshToken",
  EXP: "expToken",
  ROLE: "auth:role",
  TYPE_TOKEN: "auth:typeToken",
  USER_ID: "auth:userID",
  COMPANY_ID: "auth:companyID",
  PERMISSIONS: "auth:permissions",
};
