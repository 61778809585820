import { FC } from "react";
import classNames from "classnames/dedupe";
import { cn } from "helpers/bem";
import "./styles.scss";
import { ButtonColors } from "types/ButtonColors";

const b = cn("app-button");

interface Props {
  className?: string;
  text: string;
  color?: ButtonColors;
  onClick: () => void;
}

export const AppButton: FC<Props> = (props) => {
  const { className, text, color = ButtonColors.BLUE, onClick } = props;

  function getButtonColor(color: ButtonColors) {
    switch (color) {
      case ButtonColors.BLUE:
        return `bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline-indigo-600`;
      case ButtonColors.RED:
        return `bg-red-500 text-white hover:bg-red-400 focus-visible:outline-red-600`;
      case ButtonColors.GRAY:
        return `bg-white text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50`;
      default:
        return `bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline-indigo-600`;
    }
  }

  return (
    <button
      onClick={onClick}
      className={classNames(
        b(),
        className,
        "rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
        getButtonColor(color)
      )}
    >
      {text}
    </button>
  );
};
