import React, { useContext } from "react";
import "./App.scss";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { AuthPage, UsersPage } from "pages";
import { AuthContext, AuthProvider } from "context/AuthContext";
import { ROUTES } from "routes";

export const PrivateRoute = ({ children }: any) => {
  const context = useContext(AuthContext);

  console.log("context", context);

  // @ts-ignore
  return context.token ? children : <Navigate to={ROUTES.AUTH} />;
};

export const routes = createBrowserRouter([
  {
    path: ROUTES.AUTH,
    element: <AuthPage />,
  },
  {
    path: ROUTES.USERS,
    element: (
      <PrivateRoute>
        <UsersPage />
      </PrivateRoute>
    ),
  },
]);

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={routes} />
    </AuthProvider>
  );
}

export default App;
