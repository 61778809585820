import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation login($email: Email!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refresh($accessToken: String!, $refreshToken: String!) {
    refresh(accessToken: $accessToken, refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;
