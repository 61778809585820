import { FC, InputHTMLAttributes, FormEvent, ReactNode } from "react";
import classNames from "classnames/dedupe";
import { cn } from "helpers/bem";
import "components/ui/AppInput/styles.scss";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

const b = cn("app-input");

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label?: string;
  error?: string;
  value: string;
  id: string;
  icon?: ReactNode;
  onChange: (e: FormEvent<HTMLInputElement>) => void;
}

export const AppInput: FC<Props> = (props) => {
  const { className, label, error, value, onChange, id, icon, ...rest } = props;

  return (
    <div className={classNames(b(), className)}>
      {label && (
        <label
          htmlFor={id}
          className="block text-sm font-medium leading-6 text-gray-900 mb-2"
        >
          {label}
        </label>
      )}
      <div className="relative">
        <div className="relative flex items-center">
          {icon && <span className={b("icon")}>{icon}</span>}
          <input
            className={classNames(
              "block outline-none w-full bg-white rounded-md border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-[36px] max-h-[36px]",
              error &&
                "pr-10 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500",
              icon && "pl-[32px]"
            )}
            value={value}
            onChange={onChange}
            id={id}
            {...rest}
          />
        </div>

        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {error}
        </p>
      )}
    </div>
  );
};
