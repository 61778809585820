// @ts-nocheck
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

type AuthParams = {
  username: string;
  password: string;
};

type LoginResponse = {
  accessToken: string;
  refreshToken: string;
  token_type: string;
};

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.stage.hrboard.ru/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(STORAGE_KEYS.ACCESS);

      headers.set("Authorization", `Bearer ${token}`);

      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, AuthParams>({
      query: ({ username, password }) => {
        const payload = new FormData();
        payload.append("username", username);
        payload.append("password", password);

        return {
          url: "/auth/login",
          method: "POST",
          body: payload,
        };
      },
    }),
  }),
});

export const { useLoginMutation } = authApi;
