import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import classNames from "classnames/dedupe";
import { cn } from "helpers/bem";
import "./styles.scss";
import {
  AppButton,
  AppHeader,
  AppInput,
  AppSearch,
  AppSelect,
  AppSidebar,
  AppTag,
  IconButton,
  TWSelectOption,
} from "components";
import { AdjustmentsVerticalIcon, MinusIcon } from "@heroicons/react/24/solid";
import {
  EnvelopeIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { ButtonColors } from "types/ButtonColors";
import { useQuery } from "@apollo/client";
import { LIST_USERS } from "services/apollo/users";
import moment from "moment";
import OffsetPagination from "components/OffsetPagination/OffsetPagination";
import { roleOptions, statusOptions, tariffOptions } from "consts/consts";
import { TagColorType } from "types/TagColorType";
import { DeleteUserModal } from "pages/UsersPage/components";
import { UserModeType } from "types/UserModeType";

const b = cn("users-page");

interface Props {
  className?: string;
}

export const UsersPage: FC<Props> = (props) => {
  const { className } = props;
  const checkbox = useRef<any>();

  const [modalOpen, setModalOpen] = useState(false);
  const [mode, setMode] = useState<UserModeType>(UserModeType.EDIT);
  const [search, setSearch] = useState("");
  const [isFilters, setIsFilters] = useState(false);
  const [currentID, setCurrentID] = useState<null | string>(null);
  const [currentUser, setCurrentUser] = useState<null | any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [signupDateFrom, setSignupDateFrom] = useState("");
  const [signupDateTo, setSignupDateTo] = useState("");
  const [status, setStatus] = useState<TWSelectOption | null>(null);
  const [tariff, setTariff] = useState<TWSelectOption | null>(null);
  const [role, setRole] = useState<TWSelectOption | null>(null);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState<any>([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [payload, setPayload] = useState({
    pagination: {
      limit: 10,
      offset,
    },
  });

  const { data, loading, refetch } = useQuery(LIST_USERS, {
    variables: payload,
  });

  function updateUsers() {
    if (!loading) {
      const newPayload: any = {
        ...payload,
      };

      if (
        search ||
        role ||
        signupDateFrom ||
        signupDateTo ||
        tariff ||
        status ||
        signupDateFrom ||
        signupDateTo
      ) {
        newPayload.filters = {};
      }

      newPayload.pagination.offset = offset;

      if (search) {
        newPayload.filters.name = search;
      }

      if (role) {
        newPayload.filters.roles = [role.key];
      }

      if (tariff) {
        newPayload.filters.plan = [tariff.key];
      }

      if (status) {
        newPayload.filters.planStatus = status.key;
      }

      if (signupDateFrom) {
        newPayload.filters.createAtAfter = new Date(
          signupDateFrom
        ).toISOString();
      }

      if (signupDateTo) {
        newPayload.filters.createdAtBefore = new Date(
          signupDateTo
        ).toISOString();
      }

      setTotal(data?.listUsers?.pagination?.total || 0);

      setPayload(newPayload);

      refetch();
    }
  }

  function toggleAll() {
    setSelectedPeople(checked || indeterminate ? [] : data?.listUsers?.data);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  function onChangePagination(e: number) {
    const offset = (e - 1) * 10;

    setOffset(offset);
  }

  function openDeleteModal(userID?: string) {
    if (userID) {
      setCurrentID(userID);
    }

    setModalOpen(true);
  }

  async function clearFilters() {
    setSignupDateFrom("");
    setSignupDateTo("");
    setTariff(null);
    setRole(null);
    setStatus(null);
    setSearch("");

    setPayload({
      pagination: {
        limit: 10,
        offset,
      },
    });

    await refetch();
  }

  function getFullName(user: any) {
    let fullName = user.name;

    if (user.secondName) {
      fullName += ` ${user.secondName}`;
    }

    if (user.patronymic) {
      fullName += ` ${user.patronymic}`;
    }

    return fullName;
  }

  useEffect(() => {
    if (search || role || signupDateFrom || signupDateTo || status || tariff) {
      setIsFilters(true);
    } else {
      setIsFilters(false);
    }
  }, [search, status, role, signupDateFrom, signupDateTo, tariff]);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedPeople.length > 0 &&
      selectedPeople.length < data?.listUsers?.data?.length;

    setChecked(selectedPeople.length === data?.listUsers?.data?.length);

    setIndeterminate(isIndeterminate);

    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedPeople]);

  useEffect(() => {
    if (!modalOpen) {
      setCurrentID(null);
      setSelectedPeople([]);
    }
  }, [modalOpen]);

  useEffect(() => {
    if (!sidebarOpen) {
      setCurrentID(null);
    }
  }, [sidebarOpen]);

  useEffect(() => {
    updateUsers();
  }, [
    role,
    tariff,
    signupDateFrom,
    signupDateTo,
    status,
    loading,
    offset,
    search,
  ]);

  return (
    <div className={classNames(b(), className)}>
      <AppHeader />
      <div className="mx-auto max-w-7xl px-6 lg:px-8 py-8">
        <div className="flex items-center mb-5">
          <AppSearch
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />

          <IconButton
            icon={<AdjustmentsVerticalIcon />}
            className="ml-2"
            isActive={isOpen}
            onClick={() => setIsOpen(!isOpen)}
          />

          {isFilters && (
            <IconButton
              icon={<XCircleIcon />}
              iconType="stroke"
              className="ml-2"
              onClick={clearFilters}
            />
          )}

          <div className="ml-auto flex items-center gap-2">
            {selectedPeople.length ? (
              <>
                <AppButton
                  text="Удалить"
                  color={ButtonColors.RED}
                  onClick={() => openDeleteModal()}
                />
                <IconButton icon={<EnvelopeIcon />} onClick={() => ""} />
              </>
            ) : (
              <AppButton
                text="Добавить"
                onClick={() => {
                  setMode(UserModeType.CREATE);
                  setSidebarOpen(true);
                }}
              />
            )}
          </div>
        </div>

        {isOpen && (
          <>
            <div className="flex items-center gap-[16px]">
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">
                  Дата регистрации
                </label>

                <div className="flex items-center">
                  <AppInput
                    className="w-[130px]"
                    value={signupDateFrom}
                    id="signupDateFrom"
                    onChange={(e) => setSignupDateFrom(e.currentTarget.value)}
                    type="date"
                  />
                  <MinusIcon className="w-5 h-5 ml-2 mr-2 text-[#4F46E5]" />
                  <AppInput
                    className="w-[130px]"
                    value={signupDateTo}
                    id="signupDateTo"
                    onChange={(e) => setSignupDateTo(e.currentTarget.value)}
                    type="date"
                  />
                </div>
              </div>

              <AppSelect
                className="w-[200px]"
                label="Статус"
                options={statusOptions}
                value={status}
                onChange={(e) => setStatus(e)}
              />

              <AppSelect
                className="w-[200px]"
                label="Тариф"
                options={tariffOptions}
                value={tariff}
                onChange={(e) => setTariff(e)}
              />

              <AppSelect
                className="w-[200px]"
                label="Роль"
                options={roleOptions}
                value={role}
                onChange={(e) => setRole(e)}
              />
            </div>
          </>
        )}

        <div>
          <div className="mt-10 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 uppercase relative w-[44px]"
                        >
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            ref={checkbox}
                            checked={checked}
                            onChange={toggleAll}
                          />
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-500 uppercase"
                        >
                          Имя
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-medium text-gray-500 uppercase"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-medium text-gray-500 uppercase"
                        >
                          Телефон
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-medium text-gray-500 uppercase"
                        >
                          Тариф
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-medium text-gray-500 uppercase"
                        >
                          Статус
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-medium text-gray-500 uppercase"
                        >
                          Компания
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-medium text-gray-500 uppercase"
                        >
                          Роль
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-medium text-gray-500 uppercase"
                        >
                          Дата регистрации
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-medium text-gray-500 uppercase"
                        ></th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {data?.listUsers.data.map((user: any, index: number) => {
                        return (
                          <tr
                            key={index}
                            className="align-top cursor-pointer hover:bg-[#F1F3F9]"
                            onClick={() => {
                              setCurrentUser(user);
                              setCurrentID(user.id);
                              setMode(UserModeType.EDIT);
                              setSidebarOpen(true);
                            }}
                          >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-semibold text-indigo-500 relative">
                              <input
                                type="checkbox"
                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                value={user.email || ""}
                                checked={
                                  selectedPeople.find(
                                    (it: any) => it.email === user.email
                                  ) || false
                                }
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => {
                                  setSelectedPeople(
                                    e.target.checked
                                      ? [...selectedPeople, user]
                                      : selectedPeople.filter(
                                          (p: any) => p.email !== user.email
                                        )
                                  );
                                }}
                              />
                            </td>
                            <td className="py-4 pl-4 pr-3 text-sm text-indigo-500">
                              {getFullName(user)}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {user.email || "-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {user.phone || "-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {user.company.plan}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <AppTag
                                text={user.company.planStatus}
                                color={TagColorType.GREEN}
                              />
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {user.company.name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {user.role}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {moment(user.createdAt).format("DD.MM.YYYY")}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <div className="flex items-center justify-end gap-2">
                                <EnvelopeIcon className="w-[18px] h-[18px] mt-[2px] color-gray-500 cursor-pointer" />
                                <TrashIcon
                                  className="w-[18px] h-[18px] text-red-500 cursor-pointer"
                                  onClick={async (e) => {
                                    e.stopPropagation();
                                    console.log(user);
                                    await openDeleteModal(user.id);
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  {total > 10 && (
                    <OffsetPagination
                      value={Math.ceil((offset || 0) / 10) + 1}
                      pageCount={Math.ceil((total || 0) / 10)}
                      clickHandler={(e) => onChangePagination(e)}
                      defaultSizeValue={10}
                      size={10}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AppSidebar
        open={sidebarOpen}
        setOpen={setSidebarOpen}
        mode={mode}
        updateUsers={updateUsers}
        openDeleteModal={openDeleteModal}
        currentID={currentID}
        currentUser={currentUser}
      />

      <DeleteUserModal
        open={modalOpen}
        setOpen={setModalOpen}
        updateUsers={updateUsers}
        selected={selectedPeople.map((user: any) => user.id)}
        currentID={currentID}
      />
    </div>
  );
};
