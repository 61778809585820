import React, { FC, Fragment, useEffect, useState } from "react";
import classNames from "classnames/dedupe";
import { cn } from "helpers/bem";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import "./styles.scss";
import { AppInput } from "components/ui/AppInput/AppInput";
import { AppButton } from "components/ui/AppButton/AppButton";
import { TrashIcon } from "@heroicons/react/24/outline";
import { UserModeType } from "types/UserModeType";
import { useMutation } from "@apollo/client";
import { ADD_USER, EDIT_USER } from "services/apollo/users";
import moment from "moment";
import { roleOptions, statusOptions, tariffOptions } from "consts/consts";
import { AppSelect, TWSelectOption } from "components/ui/AppSelect/AppSelect";
import { DescInfo } from "components/AppSidebar/compnents";

const b = cn("app-sidebar");

interface Props {
  className?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  mode: UserModeType;
  updateUsers: () => void;
  openDeleteModal: (currentID: any) => void;
  currentID: null | string;
  currentUser: any;
}

enum SidebarTabs {
  USER = "user",
  PAYMENTS = "payments",
  ISSUES = "issues",
}

export const AppSidebar: FC<Props> = (props) => {
  const {
    className,
    open,
    setOpen,
    mode,
    updateUsers,
    openDeleteModal,
    currentID,
    currentUser,
  } = props;
  const isEdit = mode === UserModeType.EDIT;
  const [addUser] = useMutation(ADD_USER);
  const [editUser] = useMutation(EDIT_USER);

  const [currentTab, setCurrentTab] = useState(SidebarTabs.USER);
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [birthday, setBirthday] = useState("");
  const [signupDate, setSignupDate] = useState("");
  const [lastActivity, setLastActivity] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState<TWSelectOption | null>(statusOptions[0]);
  const [tariff, setTariff] = useState<TWSelectOption | null>(tariffOptions[0]);
  const [role, setRole] = useState<TWSelectOption | null>(roleOptions[0]);

  function clearFields() {
    setName("");
    setCompany("");
    setId("");
    setEmail("");
    setPhone("");
    setBirthday("");
    setSignupDate("");
    setLastActivity("");
    setEndDate("");
    setStatus(null);
    setTariff(null);
    setRole(null);
  }

  function formatDateForInput(date: Date) {
    return moment(date).format("YYYY-MM-DD");
  }

  async function handleEditUser() {
    const payload: any = {
      id: currentID,
      name,
      email,
      phone,
    };

    payload.birthday = birthday
      ? new Date(birthday).toISOString()
      : new Date().toISOString();

    if (role) {
      payload.role = role.key;
    }

    await editUser({
      variables: {
        input: payload,
      },
    });

    clearFields();

    await updateUsers();
  }

  async function handleAddUser() {
    await addUser({
      variables: {
        input: {
          name,
          email,
          birthday: new Date(birthday).toISOString(),
          phone,
        },
      },
    });

    clearFields();

    await updateUsers();
  }

  useEffect(() => {
    console.log("currentUser", currentUser);

    if (isEdit && currentUser) {
      setName(currentUser.name || "");
      setPhone(currentUser.phone || "");
      setEmail(currentUser.email || "");
      setCompany(currentUser.company.name || "");
      setId(currentUser.company.id || "");
      // setEndDate(
      //   formatDateForInput(currentUser.company.planExpirationDate) || ""
      // );
      setBirthday(formatDateForInput(currentUser.birthday) || "");
      setSignupDate(formatDateForInput(currentUser.createdAt) || "");
      setRole(
        roleOptions.find((it) => it.key === currentUser.role) || roleOptions[0]
      );
      setTariff(
        tariffOptions.find((it: any) => it.key === currentUser.company.plan) ||
          tariffOptions[0]
      );
    }
  }, [currentUser, isEdit]);

  useEffect(() => {
    if (!isEdit) {
      clearFields();
    }
  }, [open]);

  function getContent() {
    switch (currentTab) {
      case SidebarTabs.USER:
        return (
          <div className="flex flex-col">
            <p className="text-gray-500 text-sm mb-6">
              Заполните все необходимые поля о клиенте. Поля делятся на два
              типа: редактируемые и нередактируемые. Редактируемые поля могут
              быть заполнены автоматически при самостоятельной регистрации
              клиента или вручную менеджером. Нередактируемые поля
              устанавливаются системой автоматически. При необходимости вы
              можете заблокировать доступ клиенту, изменив его статус.
            </p>

            <div className="flex items-center gap-3 mb-4">
              <AppInput
                label="Имя"
                value={name}
                id="name"
                onChange={(e) => setName(e.currentTarget.value)}
              />
              <AppInput
                label="Компания"
                value={company}
                id="company"
                onChange={(e) => setCompany(e.currentTarget.value)}
              />
              <AppSelect
                label="Статус"
                options={statusOptions}
                value={status}
                onChange={(e) => setStatus(e)}
              />
            </div>

            <div className="flex items-center gap-3 mb-8">
              <AppInput
                label="Email"
                value={email}
                id="email"
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
              <AppInput
                label="Телефон"
                value={phone}
                id="setPhone"
                onChange={(e) => setPhone(e.currentTarget.value)}
              />
              <AppInput
                label="Дата рождения"
                value={birthday}
                id="birthday"
                onChange={(e) => setBirthday(e.currentTarget.value)}
                type="date"
              />
            </div>

            {isEdit && (
              <>
                <div className="flex items-center gap-10 mb-8">
                  <DescInfo
                    desc="Регистрация"
                    text={
                      signupDate ? moment(signupDate).format("DD.MM.YY") : "-"
                    }
                  />

                  <DescInfo
                    desc="Последняя активность"
                    text={
                      lastActivity
                        ? moment(lastActivity).format("DD.MM.YYYY")
                        : "-"
                    }
                  />
                  <DescInfo desc="ID" text={id || "-"} />
                </div>
              </>
            )}

            <p className="text-gray-500 text-sm mb-6">
              Далее представлены настройки для управления тарифом. Вы можете
              выбрать нужный тариф и указать дату его окончания. При оплате
              через сайт, тариф будет продлеваться автоматически. Также выберите
              роль в системе, которая соответствует клиенту
            </p>

            <div className="flex items-center gap-3 mb-4">
              <AppSelect
                label="Тариф"
                options={tariffOptions}
                value={tariff}
                onChange={(e) => setTariff(e)}
              />
              <AppInput
                label="Дата окончания"
                value={endDate}
                id="endDate"
                onChange={(e) => setEndDate(e.currentTarget.value)}
                type="date"
              />
              <AppSelect
                label="Роль"
                options={roleOptions}
                value={role}
                onChange={(e) => setRole(e)}
              />
            </div>

            <div className="flex items-center gap-7 mt-auto">
              {isEdit ? (
                <>
                  <AppButton
                    text="Сохранить"
                    onClick={() => {
                      handleEditUser();
                      setOpen(false);
                    }}
                  />

                  <button
                    className="text-red-500 font-medium cursor-pointer flex items-center"
                    onClick={() => {
                      openDeleteModal(currentID);
                    }}
                  >
                    <TrashIcon className="text-red-500 w-5 h-5 mr-3" />
                    Удалить
                  </button>

                  <button
                    className="text-indigo-500 font-medium cursor-pointer"
                    onClick={() => setOpen(false)}
                  >
                    Перейти к проектам
                  </button>
                </>
              ) : (
                <AppButton
                  text="Добавить"
                  onClick={() => {
                    handleAddUser();
                    setOpen(false);
                  }}
                />
              )}
            </div>
          </div>
        );
      case SidebarTabs.PAYMENTS:
        return <div>Оплаты</div>;
      case SidebarTabs.ISSUES:
        return <div>Жалобы</div>;
      default:
        return <div></div>;
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={classNames(b(), className, "relative z-10")}
        onClose={setOpen}
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-[680px]">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col pb-6">
                      <Disclosure as="nav" className="bg-white shadow">
                        <div className="mx-auto max-w-7xl">
                          <div className="relative flex h-16 justify-between">
                            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                                <button
                                  className={`outline-none inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium ${
                                    currentTab === SidebarTabs.USER
                                      ? "border-indigo-500 text-gray-900"
                                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 border-transparent"
                                  }`}
                                  onClick={() =>
                                    setCurrentTab(SidebarTabs.USER)
                                  }
                                >
                                  Пользователь
                                </button>
                                {isEdit && (
                                  <>
                                    <button
                                      className={`outline-none inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium ${
                                        currentTab === SidebarTabs.PAYMENTS
                                          ? "border-indigo-500 text-gray-900"
                                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 border-transparent"
                                      }`}
                                      onClick={() =>
                                        setCurrentTab(SidebarTabs.PAYMENTS)
                                      }
                                    >
                                      Оплаты
                                    </button>
                                    <button
                                      className={`outline-none inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium ${
                                        currentTab === SidebarTabs.ISSUES
                                          ? "border-indigo-500 text-red-500"
                                          : "border-transparent text-red-500 hover:border-gray-300 hover:text-red-700 border-transparent"
                                      }`}
                                      onClick={() =>
                                        setCurrentTab(SidebarTabs.ISSUES)
                                      }
                                    >
                                      Жалобы
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Disclosure>

                      <div className="relative mt-8 flex-1 px-8 flex">
                        {getContent()}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
