import { FC } from "react";
import classNames from "classnames/dedupe";
import { cn } from "helpers/bem";
import "./styles.scss";
import { TagColorType } from "types/TagColorType";
import { getTagTextColor } from "./helpers/getTagTextColor";
import { getTagBgColor } from "./helpers/getTagBgColor";

const b = cn("app-tag");

interface Props {
  className?: string;
  text: string;
  color?: TagColorType;
}

export const AppTag: FC<Props> = (props) => {
  const { className, text, color = TagColorType.GRAY } = props;

  return (
    <span
      className={classNames(
        b(),
        `inline-flex items-center justify-center rounded-full px-2 py-1 text-xs font-medium`,
        getTagBgColor(color),
        getTagTextColor(color),
        className
      )}
    >
      {text}
    </span>
  );
};
