import { gql } from "@apollo/client";

export const LIST_USERS = gql`
  query listUsers(
    $sort: ListUsersSort
    $filters: ListUsersFilters
    $pagination: OffsetPaginationArgs
  ) {
    listUsers(sort: $sort, filters: $filters, pagination: $pagination) {
      pagination {
        limit
        offset
        total
      }
      data {
        ... on User {
          id
          company {
            id
            name
            plan
            planStatus
            planExpirationDate
          }
          role
          createdAt
          name
          secondName
          patronymic
          email
          birthday
          lastActivity
          phone
        }
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUsers($userIDs: [NanoID!]!) {
    deleteUsers(userIDs: $userIDs)
  }
`;

export const ADD_USER = gql`
  mutation register($input: NewUser!) {
    register(input: $input) {
      id
      name
      companyID
    }
  }
`;

export const EDIT_USER = gql`
  mutation updateUser($input: NewUser!) {
    updateUser(input: $input) {
      id
      name
      companyID
      birthday
    }
  }
`;
