import React, { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import "components/OffsetPagination/style.scss";

interface IProps {
  value: number;
  pageCount: number;
  clickHandler: (e: number, s?: number) => void;
  listSize?: ISize[];
  defaultSizeValue: number;
  size?: number;
}

interface IPage {
  breakView?: boolean;
  disabled?: boolean;
  selected?: boolean;
  content?: number;
  index?: number;
}

interface ISize {
  value?: string;
  label?: string;
  key?: string;
}

export const OffsetPagination = (props: IProps) => {
  const pageRange = 3;

  const marginPages = 1;

  const [innerValue, setInnerValue] = useState<number>(1);

  const defaultValue = () => {
    if (props.size) {
      return {
        value: props.size?.toString(),
        label: props.size?.toString(),
      };
    }
    return undefined;
  };

  const [currentSize, setCurrentSize] = useState<ISize | undefined>(
    defaultValue() || props?.listSize?.[0] || undefined
  );

  const selected = () => {
    if (props.value === 0) {
      return 1;
    }
    return props.value || innerValue;
  };

  const pages = () => {
    const items = [];
    if (props.pageCount <= pageRange) {
      for (let index = 0; index < props.pageCount; index++) {
        items[index] = {
          index,
          content: index + 1,
          selected: index === selected() - 1,
        };
      }
    } else {
      const halfPageRange = Math.floor(pageRange / 2);
      const setPageItem = (index: number) => {
        // @ts-ignore
        items[index] = {
          index,
          content: index + 1,
          selected: index === selected() - 1,
        };
      };
      const setBreakView = (index: number) => {
        // @ts-ignore
        items[index] = {
          disabled: true,
          breakView: true,
        };
      };

      for (let i = 0; i < marginPages; i++) {
        setPageItem(i);
      }

      let selectedRangeLow = 0;

      if (selected() - halfPageRange > 0) {
        selectedRangeLow = selected() - 1 - halfPageRange;
      }
      let selectedRangeHigh = selectedRangeLow + pageRange - 1;
      if (selectedRangeHigh >= props.pageCount) {
        selectedRangeHigh = props.pageCount - 1;
        selectedRangeLow = selectedRangeHigh - pageRange + 1;
      }
      for (
        let i = selectedRangeLow;
        i <= selectedRangeHigh && i <= props.pageCount - 1;
        i++
      ) {
        setPageItem(i);
      }
      if (selectedRangeLow > marginPages) {
        setBreakView(selectedRangeLow - 1);
      }
      if (selectedRangeHigh + 1 < props.pageCount - marginPages) {
        setBreakView(selectedRangeHigh + 1);
      }
      for (
        let i = props.pageCount - 1;
        i >= props.pageCount - marginPages;
        i--
      ) {
        setPageItem(i);
      }
    }
    return items;
  };

  const handlePageSelected = (e: number) => {
    if (selected() === e) {
      return null;
    }
    setInnerValue(e);
    const size = parseInt(
      currentSize?.value || props?.defaultSizeValue?.toString() || "12",
      10
    );
    props.clickHandler(e, size);
  };

  const prevPage = () => {
    if (selected() <= 1) {
      return null;
    }
    handlePageSelected(selected() - 1);
  };

  const nextPage = () => {
    if (selected() >= props.pageCount) {
      return null;
    }
    handlePageSelected(selected() + 1);
  };

  const firstPageSelected = () => {
    return selected() === 1;
  };

  return (
    <div className="flex items-center justify-end border-t-gray-200 border-t-[1px] pr-3 pt-3 bg-white">
      <div className="sm:flex sm:flex-1 sm:items-center sm:justify-end">
        <div className="pb-3">
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <a
              tabIndex={firstPageSelected() ? -1 : 0}
              href="components/OffsetPagination/OffsetPagination#"
              onClick={(e) => {
                e.preventDefault();
                prevPage();
              }}
              className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            >
              <span className="sr-only">Предыдущая</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </a>
            {pages().map((page: IPage, index) => {
              return (
                <div key={page.index || index}>
                  {page?.breakView && (
                    <a
                      aria-current="page"
                      className="pagination-button cursor-default"
                    >
                      ...
                    </a>
                  )}

                  {!page?.breakView && (
                    <a
                      tabIndex={0}
                      className={
                        !page?.selected
                          ? "pagination-button"
                          : "pagination-button pagination-button__active"
                      }
                      onClick={() => handlePageSelected((page?.index || 0) + 1)}
                    >
                      {page.content}
                    </a>
                  )}
                </div>
              );
            })}

            <a
              href="components/OffsetPagination/OffsetPagination#"
              className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              onClick={(e) => {
                e.preventDefault();
                nextPage();
              }}
            >
              <span className="sr-only">Следующая</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default OffsetPagination;
