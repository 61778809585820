// @ts-nocheck
import { createContext, FC, ReactNode, useState } from "react";
import { useMutation } from "@apollo/client";
import { LOGIN } from "services/apollo/auth";
import { STORAGE_KEYS } from "consts/consts";

export const AuthContext = createContext<{
  logout: () => void;
  login?: any;
  token: string | null;
}>({});

interface Props {
  children: ReactNode;
}

export const AuthProvider: FC<Props> = (props) => {
  const { children } = props;
  const [onLogin] = useMutation(LOGIN);
  const [token, setToken] = useState<boolean | null>(null);

  async function login(username: string, password: string) {
    try {
      const payload = new FormData();

      payload.append("username", username);
      payload.append("password", password);

      const res = await onLogin({
        variables: {
          email: username,
          password,
        },
      });

      if (!res.error) {
        localStorage.setItem(STORAGE_KEYS.ACCESS, res.data.login.accessToken);
        localStorage.setItem(STORAGE_KEYS.REFRESH, res.data.login.refreshToken);
        setToken(true);
      }

      return res;
    } catch (e) {
      console.log(e);
    }
  }

  function logout() {
    localStorage.removeItem(STORAGE_KEYS.ACCESS);
    localStorage.removeItem(STORAGE_KEYS.REFRESH);
    setToken(null);
  }

  return (
    <AuthContext.Provider
      value={{
        token: localStorage.getItem(STORAGE_KEYS.ACCESS),
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
