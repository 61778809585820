// @ts-nocheck
import { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/dedupe";
import { cn } from "helpers/bem";
import "./styles.scss";
import { AppButton } from "components";
import { ROUTES } from "routes";
import { AuthContext } from "context/AuthContext";

const b = cn("auth-form");

interface Props {
  className?: string;
}

export const AuthForm: FC<Props> = (props) => {
  const { className } = props;
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  async function handleLogin() {
    const res = await login(username, password);

    if (res?.error) {
      console.log("error", res.error);
    } else {
      navigate(ROUTES.USERS);
    }
  }

  return (
    <div
      className={classNames(
        b(),
        className,
        "flex max-w-lg w-full min-h-full flex-col justify-center sm:px-6 lg:px-8"
      )}
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <form
            className="space-y-6"
            action="#"
            method="POST"
            onClick={(e) => e.preventDefault()}
          >
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Пароль
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <AppButton
              className="w-full"
              text="Войти"
              type="submit"
              onClick={() => handleLogin()}
            />
          </form>
        </div>
      </div>
    </div>
  );
};
