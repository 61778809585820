import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "services/store";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
  Observable,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { STORAGE_KEYS } from "consts/consts";
import { REFRESH_TOKEN } from "services/apollo/auth";

const container = document.getElementById("root")!;
const root = createRoot(container);

const URL = "https://api.stage.hrboard.ru/query";

const httpLink = createHttpLink({
  uri: URL,
});

export const decodeSHA = (value: string): any => {
  const parseToken = JSON.parse(atob(value.split(".")[1]));
  return {
    role: parseToken?.user_role || "",
    companyID:
      parseToken?.company_id !== undefined ? parseToken?.company_id : "",
    userID: parseToken?.user_id !== undefined ? parseToken?.user_id : "",
    exp: parseToken?.exp || "",
  };
};

const currentDate = Math.floor(new Date().getTime() / 1000);

export const authenticateUser = (data: any): any => {
  const { accessToken, refreshToken } = data;
  if (!accessToken || !refreshToken) throw new Error("Токен недоступен");
  const result: any = {
    accessToken,
    refreshToken,
  };

  const parsedToken = decodeSHA(refreshToken);

  if (accessToken) {
    localStorage.setItem(STORAGE_KEYS.ACCESS, accessToken);
  }
  if (refreshToken) {
    localStorage.setItem(STORAGE_KEYS.REFRESH, refreshToken);
  }
  if (parsedToken?.exp && currentDate < +parsedToken?.exp) {
    localStorage.setItem(STORAGE_KEYS.EXP, parsedToken.exp);
    result.expired = parsedToken?.exp;
  } else {
    throw new Error("Время токена истекло");
  }
  // @ts-ignore
  result.userData = decodeSHA(accessToken);
  // console.log('result', result);
  return result;
};

//* инициализация клиента для запроса токена
const refreshTokenApiClient = new ApolloClient({
  link: createHttpLink({ uri: URL }),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  credentials: "include",
});

const getNewToken = async () => {
  const RefreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH);
  const AccessToken = localStorage.getItem(STORAGE_KEYS.ACCESS);
  const { data } = await refreshTokenApiClient.mutate({
    mutation: REFRESH_TOKEN,
    variables: {
      refreshToken: RefreshToken,
      accessToken: AccessToken,
    },
  });
  authenticateUser(data.refresh);

  return data;
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError) {
      const errorMessage = `[Network error]: ${networkError || ""}`;
      console.error(errorMessage);
    }
    if (graphQLErrors) {
      console.log("graphQLErrors", graphQLErrors);
      console.log("operation", operation.getContext());

      return new Observable((observer) => {
        getNewToken()
          .then((refreshResponse) => {
            operation.setContext(({ headers = {} }) => ({
              headers: {
                // Re-add old headers
                ...headers,
                // Switch out old access token for new one
                authorization: `Bearer ${refreshResponse.accessToken}` || null,
              },
            }));
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };

            // Retry last failed request
            const subscription = forward(operation).subscribe(subscriber);

            // return subscription.unsubscribe();
          })
          .catch((error) => {
            // No refresh or client token available, we force user to login
            observer.error(error);

            console.log("error", error);
          });
      });
    }
  }
);

const ErrorDetector = new ApolloLink((operation, forward) => {
  // @ts-ignore
  return new Observable((observer) => {
    // @ts-ignore
    let subscription;
    try {
      subscription = forward(operation).subscribe({
        next: (result: any) => {
          const errors = result?.errors || [];
          if (errors?.length) {
            const isUnauthorized = errors.some(
              (item: any) => item.extensions?.code === 401
            );
            if (isUnauthorized) {
              getNewToken().catch(() => {
                localStorage.removeItem(STORAGE_KEYS.EXP);
                localStorage.removeItem(STORAGE_KEYS.ACCESS);
                localStorage.removeItem(STORAGE_KEYS.REFRESH);
                const url = window.location.origin;
                const redirectUrl = url + "/login";
                window.location.replace(redirectUrl);
              });
            } else {
              const err = {
                code: errors?.[0]?.extensions?.code,
                message: "error",
              };
              console.error(err);
            }
            observer.error(new Error(errors?.[0]?.extensions?.code));
          } else {
            observer.next(result);
          }
        },
        error: (networkError) => {
          observer.error(networkError);
        },
        complete: () => {
          observer.complete.bind(observer)();
        },
      });
    } catch (e) {
      console.error(e);
      observer.error(e);
    }
    return () => {
      // @ts-ignore
      if (subscription) {
        // @ts-ignore
        subscription.unsubscribe();
      }
    };
  });
});

const RetryOnError = new RetryLink({
  delay: {
    initial: 800,
    max: Infinity,
    jitter: true,
  },
  attempts: (count, operation, error) => {
    if (!error.message || error.message !== "401") {
      return false;
    }
    operation.setContext({ retryCount: count });
    return count <= 3;
  },
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(STORAGE_KEYS.ACCESS);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  uri: "https://api.stage.hrboard.ru/query",
  // @ts-ignore
  link: from([RetryOnError, ErrorDetector, errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <App />
      </Provider>
    </ApolloProvider>
  </React.StrictMode>
);
